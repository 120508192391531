body{
  background-color: var(--fourth-color);
}

.navbar-container {
  display: flex;
  margin-left: 1em;
  }
  
  .navbar-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 2em;
  margin-right: 16px;
  }
  
  .navbar {
  list-style-type: none;
  border-radius: 3%;
  margin: 0;
  padding: 0;
  width: 13%;
  border: #000 2px solid;
  background-color: #f1f1f1;
  position: fixed;
  height: 85.7%;
  overflow: auto;
  z-index: 99;
  }
  
  li a {
  font-size: 19px;
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
  }
  
  li a.active {
  background-color: var(--fifth-color);
  color: white;
  }
  
  li a:hover:not(.active) {
  background-color: #555;
  color: white;
  }
  
  @media screen and (max-width: 768px) {
  .navbar {
  width: 100%;
  height: auto;
  position: absolute;
  top: 60px;
  left: -100%;
  transition: all 0.3s ease-in;
  }
  .navbar-toggle {
    display: block;
  }
  
  .navbar.open {
    left: 0;
  }
}

header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 6em;
}


.user-menu {
    position: relative;
    margin-right: 20px;
}

.user-menu a{
  font-size: 15px;
}

.user-avatar {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.user-actions {
    display: none;
    position: absolute;
    width: 10em;
    top: 50px;
    right: 0;
    background-color: #f1f1f1;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.user-actions li {
    list-style-type: none;
    padding: 5px;
}

.user-actions li a {
    text-decoration: none;
    color: #000;
}

.user-actions.show {
  display: block;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  animation:user-actions 0.1s;
  z-index: 99;
}
@keyframes user-actions{0%{transform:scale(.5);transform-origin:center top}100%{transform:scale(1);transform-origin:center top}}

.logo {
    width: 7em;
    height: 5em;
    margin-left: 20px;
    flex-grow: 1;
}

.logo img {
    max-width: 10em; /* Para que la imagen del logo se adapte al ancho del contenedor */
    height: 100%; /* Para mantener la proporción de la imagen */
}

.border-div {
  margin-top: 3.3em;
  margin-left: 20px;
  width: calc(84% - 1px);
  height: calc(92% - 50px);
  position: fixed;
  top: 50px;
  left: 13%;
}

@media (min-width: 1px) and (max-width: 375px) {

  .border-div{
    margin-top: 100px;
    margin-left: -30px;
    width: calc(94% - 1px);
    height: calc(84% - 50px);
  }

  .logo {
    width: 5em;
    height: 4em;
    margin-left: 20px;
    flex-grow: 1;
}

.logo img {
  max-width: 10em;
  height: 100%;
}
}

@media (min-width: 376px) and (max-width: 390px) {

  .border-div{
    margin-top: 100px;
    margin-left: -45px;
    width: calc(97% - 1px);
    height: calc(84% - 50px);
  }
  .logo {
    width: 4em;
    height: 3em;
    margin-left: 18px;
    flex-grow: 1;
}

.logo img {
  max-width: 10em;
  height: 100%;
}
}

@media (min-width: 391px) and (max-width: 412px) {

  .border-div{
    margin-top: 100px;
    margin-left: -43px;
    width: calc(96% - 1px);
    height: calc(84% - 50px);
  }
  .logo {
    width: 4em;
    height: 3em;
    margin-left: 18px;
    flex-grow: 1;
}

.logo img {
  max-width: 10em;
  height: 100%;
}
}

@media (min-width: 413px) and (max-width: 786px) {

  .border-div{
    margin-top: 46px;
    margin-left: 12px;
    width: calc(84% - 1px);
    height: calc(92.5% - 50px);
  }

  .logo {
    width: 4em;
    height: 4em;
    margin-left: 18px;
    flex-grow: 1;
}

.logo img {
  max-width: 10em;
  height: 100%;
}
}

@media (min-width: 768px) and (max-width: 800px) {

  .border-div{
    margin-top: 46px;
    margin-left: 12px;
    width: calc(84% - 1px);
    height: calc(92.5% - 50px);
  }
  .logo {
    width: 4.3em;
    height: 4.3em;
    margin-left: 18px;
    flex-grow: 1;
}

.logo img {
  max-width: 10em;
  height: 100%;
}
}

@media (min-width: 801px) and (max-width: 1024px) {

  .border-div{
    margin-top: 46px;
    margin-left: 12px;
    width: calc(84% - 1px);
    height: calc(92.5% - 50px);
  }
}

@media (min-width: 1025px) {

  .border-div{
    margin-top: 46px;
    margin-left: 12px;
    width: calc(85% - 1px);
    height: calc(91% - 50px);
  }
}