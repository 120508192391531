.TableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;
    height: 36em;
    overflow-y: scroll;
  }
  
  .TableContainer::-webkit-scrollbar {
    height: 2.4rem;
  }
  
  .TableContainer::-webkit-scrollbar-thumb {
    background-color: #636363;
    border: 0.8rem solid #ffffff;
    border-top-width: 0;
  }
  
  .wrapper {
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .table {
    margin: 0 0 40px 0;
    width: 100%;
    display: table;
  }
  
  @media screen and (max-width: 580px) {
    .table {
      display: block;
    }
  }
  
  .row {
    display: table-row;
    background: #f6f6f6;
  }
  
  .row:nth-of-type(odd) {
    background: #e9e9e9;
  }
  
  .row.header {
    font-weight: 900;
    color: #ffffff;
    
  }
  
  .row.green {
    background: #27ae60;
  }
  
  .row.blue {
    background: var(--fifth-color);
  }
  
  @media screen and (max-width: 580px) {
    .row {
      padding: 14px 0 7px;
      display: block;
    }
  
    .row.header {
      padding: 0;
      height: 6px;
    }
  
    .row.header .cell {
      display: none;
    }
  
    .row .cell {
      margin-bottom: 10px;
    }
  
    .row .cell:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #969696;
      display: block;
    }
  }
  
  .cell {
    padding: 6px 12px;
    display: table-cell;
  }
  
  @media screen and (max-width: 580px) {
    .cell {
      padding: 2px 16px;
      display: block;
    }
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: var(--first-color);
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #2980b9;
  }
  
  .panel-table {
    margin-bottom: 10px;
  }
  
  .panel-table input[type="text"] {
    margin-right: 5px;
    padding: 5px;
  }
  
  .panel-table button {
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #2980b9;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }
  
  .panel-table button.create {
    background-color: var(--fifth-color);
  }
  
  @media screen and (max-width: 580px) {
    .TableContainer {
      overflow-y: visible;
    }
  
    .wrapper {
      overflow-x: scroll;
    }
  
    .pagination {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .input-field-style {
    padding: 14px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .input-field-style:focus {
    outline: none;
    border-color: #2196f3;
    box-shadow: 0 0 5px #2196f3;
  }
  
  @media (min-width: 391px) and (max-width: 412px) {

    .border-div{
      margin-top: 100px;
      margin-left: -43px;
      width: calc(96% - 1px);
      height: calc(84% - 50px);
    }
    .logo {
      width: 4em;
      height: 3em;
      margin-left: 18px;
      flex-grow: 1;
  }
  
  .logo img {
    max-width: 10em;
    height: 100%;
  }
  }
  
  @media (min-width: 413px) and (max-width: 786px) {
  
    .border-div{
      margin-top: 46px;
      margin-left: 12px;
      width: calc(84% - 1px);
      height: calc(92.5% - 50px);
    }
  
    .logo {
      width: 4em;
      height: 4em;
      margin-left: 18px;
      flex-grow: 1;
  }
  
  .logo img {
    max-width: 10em;
    height: 100%;
  }
  }
  
  @media (min-width: 768px) and (max-width: 800px) {
  
    .border-div{
      margin-top: 46px;
      margin-left: 12px;
      width: calc(84% - 1px);
      height: calc(92.5% - 50px);
    }
    .logo {
      width: 4.3em;
      height: 4.3em;
      margin-left: 18px;
      flex-grow: 1;
  }
  
  .logo img {
    max-width: 10em;
    height: 100%;
  }
  }
  
  @media (min-width: 801px) and (max-width: 1024px) {
  
    .border-div{
      margin-top: 46px;
      margin-left: 12px;
      width: calc(84% - 1px);
      height: calc(92.5% - 50px);
    }
  }
  
  @media (min-width: 1025px) {
  
    .border-div{
      margin-top: 46px;
      margin-left: 12px;
      width: calc(85% - 1px);
      height: calc(91% - 50px);
    }
  }