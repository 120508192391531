@import url(https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,700);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.container {
    font-family: 'Open Sans';
    font-weight: 100;
    display: flex;
    overflow: hidden;
}

input ::-webkit-input-placeholder {
    color: black;
}

input ::-moz-placeholder {
    color: black;
}

input :-ms-input-placeholder {
    color: black;
}

input:focus {
    outline: 0 transparent solid;
}

input:focus ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.7);
}

input:focus ::-moz-placeholder {
    color: rgba(0, 0, 0, 0.7);
}

input:focus :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.7);
}

.login-form {
    min-height: 10rem;
    margin: 10em auto;
    max-width: 60%;
    padding: 0.5rem;
}

.login-text {
    color: white;
    font-size: 1.5rem;
    margin: 0 auto;
    width: 14em;
    padding: 0.5rem;
    text-align: center;
}

.login-text .custom-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-text .fa-stack-1x {
    color: black;
}

.login-username, .login-password {
    background: white;
    border: 0 solid;
    border-bottom: 5px solid white;
    color: rgb(0, 0, 0);
    display: block;
    margin: 1rem;
    padding: 0.5rem;
    transition: 250ms background ease-in;
    width: calc(100% - 3rem);
}

.login-username:focus, .login-password:focus {
    background: white;
    color: black;
    transition: 250ms background ease-in;
}

.login-forgot-pass {
    display: none;
    display: none;
    bottom: 0;
    color: black;
    background: white;
    cursor: pointer;
    display: block;
    font-size: 15px;
    left: 0;
    opacity: 10000%;
    padding: 0.5rem;
    position: absolute;
    text-align: center;
    width: 14em;
    margin: auto auto 10em 43%;
    
}

.login-forgot-pass:hover {
    opacity: 10;
}

.login-submit {
    border: 2px solid black;
    background: white;
    color: rgb(0, 0, 0);
    display: block;
    margin: 1rem auto;
    width: 10em;
    text-align: center;
    padding: 0.25rem;
    transition: 250ms background ease-in;
}

.login-submit:hover, .login-submit:focus {
    background: white;
    color: black;
    transition: 250ms background ease-in;
}

[class*=underlay] {
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    top: 0;
}

.underlay-photo {
    animation: hue-rotate 6s infinite;
    background: url('');
    background-size: cover;
    background-position: center top 30%;
    -webkit-filter: grayscale(30%);
    z-index: -1;
}

.underlay-black {

    z-index: -1;
}

@keyframes hue-rotate {
    from {
        -webkit-filter: grayscale(30%) hue-rotate(0deg);
    }

    to {
        -webkit-filter: grayscale(30%) hue-rotate(360deg);
    }
}

@media (min-width: 100px) and (max-width: 375px) {

    .login-form {
        min-height: 10rem;
        margin: 10em auto;
        max-width: 60%;
        padding: 0.5rem;
    }
    
    .login-text {
        color: black;
        font-size: 1.5rem;
        margin: 0 auto;
        width: 14em;
        padding: 0.5rem;
        text-align: center;
    }
    
    .login-text .custom-icon {
        position: absolute;
        top: 50%;
        left: -60%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .login-username, .login-password {
        background: white;
        border: 0 solid;
        border-bottom: 5px solid white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem;
        padding: 0.5rem;
        transition: 250ms background ease-in;
        width: calc(100% - 3rem);
    }
    
    .login-forgot-pass {
        display: none;
        bottom: 0;
        color: black;
        background: white;
        cursor: pointer;
        display: block;
        font-size: 15px;
        left: 0;
        opacity: 10000%;
        padding: 0.5rem;
        position: absolute;
        text-align: center;
        width: 14em;
        margin: auto auto 10em 20%;
        
    }
    
    .login-forgot-pass:hover {
        opacity: 10;
    }
    
    .login-submit {
        border: 2px solid black;
        background: white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem auto auto 12%;
        width: 10em;
        text-align: center;
        padding: 0.25rem;
        transition: 250ms background ease-in;
    }
  }

  @media (min-width: 376px) and (max-width: 390px) {

    .login-form {
        min-height: 10rem;
        margin: 10em auto;
        max-width: 60%;
        padding: 0.5rem;
    }
    
    .login-text {
        color: black;
        font-size: 1.5rem;
        margin: 0 auto;
        width: 14em;
        padding: 0.5rem;
        text-align: center;
    }
    
    .login-text .custom-icon {
        position: absolute;
        top: 50%;
        left: -60%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .login-username, .login-password {
        background: white;
        border: 0 solid;
        border-bottom: 5px solid white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem;
        padding: 0.5rem;
        transition: 250ms background ease-in;
        width: calc(100% - 3rem);
    }
    
    .login-forgot-pass {
        display: none;
        bottom: 0;
        color: black;
        background: white;
        cursor: pointer;
        display: block;
        font-size: 15px;
        left: 0;
        opacity: 10000%;
        padding: 0.5rem;
        position: absolute;
        text-align: center;
        width: 14em;
        margin: auto auto 10em 23%;
        
    }
    
    .login-forgot-pass:hover {
        opacity: 10;
    }
    
    .login-submit {
        border: 2px solid black;
        background: white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem auto auto 15%;
        width: 10em;
        text-align: center;
        padding: 0.25rem;
        transition: 250ms background ease-in;
    }
  }

  @media (min-width: 391px) and (max-width: 800px) {

    .login-form {
        min-height: 10rem;
        margin: 10em auto;
        max-width: 60%;
        padding: 0.5rem;
    }
    
    .login-text {
        color: black;
        font-size: 1.5rem;
        margin: 0 auto;
        width: 14em;
        padding: 0.5rem;
        text-align: center;
    }
    
    .login-text .custom-icon {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .login-username, .login-password {
        background: white;
        border: 0 solid;
        border-bottom: 5px solid white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem;
        padding: 0.5rem;
        transition: 250ms background ease-in;
        width: calc(100% - 3rem);
    }
    
    .login-forgot-pass {
        display: none;
        bottom: 0;
        color: black;
        background: white;
        cursor: pointer;
        display: block;
        font-size: 15px;
        left: 0;
        opacity: 10000%;
        padding: 0.5rem;
        position: absolute;
        text-align: center;
        width: 14em;
        margin: auto auto 10em 37%;
        
    }
    
    .login-forgot-pass:hover {
        opacity: 10;
    }
    
    .login-submit {
        border: 2px solid black;
        background: white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem auto auto 25%;
        width: 10em;
        text-align: center;
        padding: 0.25rem;
        transition: 250ms background ease-in;
    }
  }

  @media (min-width: 801px) and (max-width: 1024px) {

    .login-form {
        min-height: 10rem;
        margin: 10em auto;
        max-width: 60%;
        padding: 0.5rem;
    }
    
    .login-text {
        color: black;
        font-size: 1.5rem;
        margin: 0 auto;
        width: 14em;
        padding: 0.5rem;
        text-align: center;
    }
    
    .login-text .custom-icon {
        position: absolute;
        top: 50%;
        left: 23%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .login-username, .login-password {
        background: white;
        border: 0 solid;
        border-bottom: 5px solid white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem;
        padding: 0.5rem;
        transition: 250ms background ease-in;
        width: calc(100% - 3rem);
    }
    
    .login-forgot-pass {
        display: none;
        bottom: 0;
        color: black;
        background: white;
        cursor: pointer;
        display: block;
        font-size: 15px;
        left: 0;
        opacity: 10000%;
        padding: 0.5rem;
        position: absolute;
        text-align: center;
        width: 14em;
        margin: auto auto 10em 39%;
        
    }
    
    .login-forgot-pass:hover {
        opacity: 10;
    }
    
    .login-submit {
        border: 2px solid black;
        background: white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem auto auto 25%;
        width: 10em;
        text-align: center;
        padding: 0.25rem;
        transition: 250ms background ease-in;
    }
  }

   @media (min-width: 1025px) {

    .login-form {
        min-height: 10rem;
        margin: 10em auto;
        max-width: 60%;
        padding: 0.5rem;
    }
    
    .login-text {
        color: black;
        font-size: 1.5rem;
        margin: 0 auto;
        width: 14em;
        padding: 0.5rem;
        text-align: center;
    }
    
    .login-text .custom-icon {
        position: absolute;
        top: 50%;
        left: 23%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .login-username, .login-password {
        background: white;
        border: 0 solid;
        border-bottom: 5px solid white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem;
        padding: 0.5rem;
        transition: 250ms background ease-in;
        width: calc(100% - 3rem);
    }
    
    .login-forgot-pass {
        display: none;
        bottom: 0;
        color: black;
        background: white;
        cursor: pointer;
        display: block;
        font-size: 15px;
        left: 0;
        opacity: 10000%;
        padding: 0.5rem;
        position: absolute;
        text-align: center;
        width: 14em;
        margin: auto auto 10em 39%;
        
    }
    
    .login-forgot-pass:hover {
        opacity: 10;
    }
    
    .login-submit {
        border: 2px solid black;
        background: white;
        color: rgb(0, 0, 0);
        display: block;
        margin: 1rem auto auto 25%;
        width: 10em;
        text-align: center;
        padding: 0.25rem;
        transition: 250ms background ease-in;
    }
  }